import React from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { truncateString } from "../../../utilities/truncate";

function LatestCard({ data }) {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle card click to navigate to article page
  const handleCardClick = () => {
    navigate(`/article/${data.id}`); // Redirect to article/:id page
  };

  return (
    <Card className="d-flex flex-row p-1 m-2" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <Card.Body style={{ width: '80%' }}>
        <Card.Text className="fs-7 hover-red">{truncateString(data.title,50)}</Card.Text>
      </Card.Body>
      {/* Use the image from data instead of a random one */}
      <Card.Img variant="top" src={data.imageUrl} className="p-2" style={{ width: '20%' }} />
    </Card>
  );
}

export default LatestCard;


