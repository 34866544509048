import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/home/Home';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Article from './components/news/article/Article';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Signin from './components/auth/Signin';
import Newsupload from './components/upload/Newsupload';
import Admincp from './components/upload/Admincp';
import NewsResults from './components/news/news-results/NewsResults';
import AboutUs from './components/aboutUs/AboutUs';
import AdvertiseWithUs from './components/advertise/AdvertiseWithUs';
import Disclaimer from './components/disclaimer/Disclaimer';
import  Policy from './components/policy/Policy';




function App() {
  const router=createBrowserRouter([
    {
      path:'/',
      element:<Home></Home>
    },{
      path:'/article/:id',
      element:<Article></Article>
    },
    {
      path:'/auth/signin',
      element:<Signin></Signin>
    },
    {
      path:'/upload',
      element:<Newsupload></Newsupload>
    },
    {
      path:'/admincp',
      element:<Admincp></Admincp>
    }, 
    {
      path:'news/results',
      element:<NewsResults></NewsResults>
    },
    {
      path:'/aboutUs',
      element:<AboutUs></AboutUs>
    },
    {
      path:'/advertise',
      element:<AdvertiseWithUs></AdvertiseWithUs>
    },
    {
      path:'/disclaimer',
      element:<Disclaimer></Disclaimer>
    },
    {
      path:'/policy',
      element:<Policy></Policy>
    }
   
  ])
  return (

    <RouterProvider router={router}></RouterProvider>
    
  )
}


export default App