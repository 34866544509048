import React, { useRef, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import './scrolling.css';
import { useNavigate } from 'react-router-dom';

function ScrollingCard({ data }) {
  const scrollRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const cardRef = useRef(null); // Ref to measure card width
const navigate=useNavigate()

  useEffect(() => {
    // Calculate the width of the first card after the component mounts
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, [data]); // Update width when data changes

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -cardWidth, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' });
  };

  return (
    <div className="scrolling-container position-relative">
      <Button variant="outline-secondary" className="scroll-btn left-btn" onClick={scrollLeft}>
        &#10094;
      </Button>
      <div className="scrolling-wrapper d-flex overflow-hidden" ref={scrollRef}>
        {data.map((item, index) => (
          <Card onClick={()=>navigate('/article/'+item.id)} key={index} className="scrolling-card shadow-sm mx-2" ref={index === 0 ? cardRef : null}>
            {item.type === 'video' ? (
              <video width="100%" height="180" controls>
                <source src={item.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <Card.Img variant="top" src={item.imageUrl} className="scrolling-image" />
            )}
            <Card.Body>
              <Card.Title className="fs-6 text-center hover-red">{item.title}</Card.Title>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Button variant="outline-secondary" className="scroll-btn right-btn" onClick={scrollRight}>
        &#10095;
      </Button>
    </div>
  );
}

export default ScrollingCard;
