import React, { useEffect, useState } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";  // Import Spinner for loading
import Top from "./top/Top";
import Featured from "./featured/Featured";
import Latest from "./latest/Latest";
import { collection, getDocs, query, where } from 'firebase/firestore'; 
import { db } from '../../myfirestore';  
import ScrollingCard from "./scrolling/ScrollingCard";
import CategoryGrid from "./categoryGrid/CategoryGrid";
import Footer from "./footer/Footer";
import Shorts from "./shorts/Shorts";

function News() {
  const [top, setTop] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [common, setCommon] = useState([]);
  const [flash, setFlash] = useState([]);
  const [short, setShort] = useState([]);
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);  // Error state

  useEffect(() => {
    const fetchNewsArticles = async () => {
      try {
        const q = query(collection(db, 'news'), where('approved', '==', true));
        const querySnapshot = await getDocs(q);
        
        const articles = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Filter articles by type
        setTop(articles.filter(article => article.type === 'Top'));
        setFeatured(articles.filter(article => article.type === 'Featured'));
        setCommon(articles.filter(article => article.type === 'Common'));
        setFlash(articles.filter(article => article.type === 'Flash'));
        setShort(articles.filter(article => article.type === 'Short'));

      } catch (error) {
        setError(error);  // Set error state on error
        console.error('Error fetching news articles: ', error);
      } finally {
        setLoading(false);  // Set loading to false after fetching
      }
    };

    fetchNewsArticles();
  }, []);

  if (loading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" />
        <p>Loading news articles...</p>
      </Container>
    );
  }

  if (error) {
    return <div>Error fetching articles: {error.message}</div>;
  }

  return (
    <React.Fragment>
      <Container fluid className="border rounded shadow my-5 p-4">
        <Row>
          <Col lg={9}>
            <div className="mb-5">
              <h2>టాప్ స్టోరీస్</h2>
              <Top data={top} />
            </div>
          </Col>
          <Col lg={3} className="border-start border-2">
            <h3 className="text-danger">CTV Specials</h3>
            <Featured data={featured} />
          </Col>
        </Row>
        <hr />
        <Row>
          <div className="my-5">
            <h2>Orginals</h2>
            <ScrollingCard data={flash} />
          </div>
          <hr />
          <div className="my-5">
            <h2 className="my-5">LATEST NEWS</h2>
            <Latest data={common} />
          </div>
        </Row>
        <hr />
        <Row>
        <div className="my-5">
        <h2>వెబ్ స్టోరీస్</h2>
          <Shorts data={short}></Shorts>
          </div>
        </Row>
        <hr />
        <Row>
          <CategoryGrid />
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default News;
