import React from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { truncateString } from '../../../utilities/truncate';

function TopSubCard({ data }) {
  const navigate = useNavigate();

  // Handle card click to navigate to article page
  const handleCardClick = () => {
    navigate(`/article/${data.id}`);  // Redirect to article/:id page
  };

  return (
    <Card className='h-100' onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      {/* Use the image from data instead of a random one */}
      <Card.Img style={{height:'50%'}} variant="top" src={data.imageUrl} alt={data.title} />
      <Card.Body style={{height:'50%'}} className='p-2'>
        <Card.Text className='fs-8 hover-red'>{data.title}</Card.Text>
      
        {/* <Card.Text className='fs-11'>
          {truncateString(data.synopsis)}
        </Card.Text> */}
      </Card.Body>
    </Card>
  );
}

export default TopSubCard;
