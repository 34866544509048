import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Form, ListGroup, Overlay } from 'react-bootstrap';
import logo from '../../../assets/ctv.png';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../myfirestore';  // Adjust the path to your config file

const SocialMediaIcons = () => (
  <>
    <Navbar.Text>
      <a href="/" aria-label="Home">
        <i className="bi bi-house-door-fill text-danger"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
        <i className="bi bi-instagram text-danger"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
        <i className="bi bi-facebook text-primary"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <i className="bi bi-linkedin text-info"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
      <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
        <i className="bi bi-youtube text-danger"></i>
      </a>
    </Navbar.Text>
    <Navbar.Text>
      <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
        <i className="bi bi-whatsapp text-success"></i>
      </a>
    </Navbar.Text>
  </>
);

function MainNav() {
  const [searchQuery, setSearchQuery] = useState('');
  const [articles, setArticles] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  // Organized Andhra Pradesh and Telangana districts
  const districts = [
    {
      state: 'Andhra Pradesh', 
      districts: [
        'Anantapur', 'Chittoor', 'East Godavari', 'Guntur', 'Kadapa', 
        'Krishna', 'Kurnool', 'Nellore', 'Prakasam', 'Srikakulam', 
        'Visakhapatnam', 'Vizianagaram', 'West Godavari'
      ]
    },
    {
      state: 'Telangana', 
      districts: [
        'Adilabad', 'Hyderabad', 'Karimnagar', 'Khammam', 'Mahabubnagar', 
        'Medak', 'Nalgonda', 'Nizamabad', 'Rangareddy', 'Warangal'
      ]
    }
  ];

  // Handle district selection
  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    if (selectedDistrict) {
      navigate(`/news/results?district=${selectedDistrict}`);
    }
  };
  // Function to fetch all articles from Firestore
  const fetchAllArticles = async () => {
    const articlesRef = collection(db, 'news');
    const querySnapshot = await getDocs(articlesRef);
    const articles = [];
    querySnapshot.forEach((doc) => {
      articles.push({ id: doc.id, ...doc.data() });
    });
    return articles;
  };

  // Function to filter articles by search term
  const filterAndScoreArticles = (articles, searchTerm) => {
    const lowerCaseSearch = searchTerm.toLowerCase();
  
    // Score articles based on search term matches in specific fields
    const scoredArticles = articles
      .filter((article) => article.approved === true) // Only include approved articles
      .map((article) => {
        let score = 0;
  
        // Increment score for each field that matches the search term
        if (article.title?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.content?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.author?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.category?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.date?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.description?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.districtName?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.synopsis?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.type?.toLowerCase().includes(lowerCaseSearch)) score++;
        if (article.tags?.some((tag) => tag.toLowerCase().includes(lowerCaseSearch))) score++;
  
        return { ...article, score }; // Include score in each article object
      });
  
    // Sort first by score (descending) and then by date (most recent first)
    return scoredArticles
      .sort((a, b) => b.score - a.score || new Date(b.date) - new Date(a.date)) // Sort by score, then by date
      .slice(0, 10); // Return the top 10 articles
  };
  
  

  // Handle search input change
  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      const allArticles = await fetchAllArticles();
      const results = filterAndScoreArticles(allArticles, query);
      setArticles(results);
      setShowDropdown(results.length > 0);
    } else {
      setShowDropdown(false);
      setArticles([]);
    }
  };

  // Navigate to article details
  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
    setShowDropdown(false); // Close dropdown after navigation
  };

  return (
    <Navbar className="bg-body-tertiary shadow-sm border">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="CTV News Logo" height="50" className="d-inline-block align-top" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end gap-3 fs-5">
          <SocialMediaIcons />
          <Form.Select 
            className="w-auto" 
            aria-label="Select District" 
            onChange={handleDistrictChange}
          >
            <option value="">Select District</option>
            {districts.map((group, idx) => (
              <optgroup label={group.state} key={idx}>
                {group.districts.map((district, index) => (
                  <option key={index} value={district}>
                    {district}
                  </option>
                ))}
              </optgroup>
            ))}
          </Form.Select>
          <Form.Control
            ref={searchInputRef}
            type="text"
            placeholder="Search news..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-auto"
            aria-label="Search"
          />
          {/* Search Results Dropdown */}
          <Overlay
            target={searchInputRef.current}
            show={showDropdown}
            placement="bottom"
          >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  borderRadius: '5px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  zIndex: 1000,
                  ...props.style,
                }}
              >
                <ListGroup>
                  {articles.length > 0 ? (
                    articles.map((article) => (
                      <ListGroup.Item
                        key={article.id}
                        action
                        onClick={() => handleArticleClick(article.id)}
                      >
                        {article.title}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>No articles found.</ListGroup.Item>
                  )}
                </ListGroup>
              </div>
            )}
          </Overlay>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNav;