import React from 'react';
import './top.css';
import TopMainCard from './TopMainCard';
import { Col, Row } from 'react-bootstrap';
import TopSubCard from './TopSubCard';

// Helper function to sort by most recent date
const sortByRecent = (array) => {
  return array.sort((a, b) => new Date(b.date) - new Date(a.date));  // Assuming 'date' field exists
};

const splitArray = (array) => {
  const [first, second, ...rest] = array;
  return {
    firstTwo: [first, second].filter(Boolean), // Ensures that only non-undefined values are included
    rest
  };
};

function Top({ data }) {
  // Sort the data by most recent date before splitting
  const sortedData = sortByRecent(data);
  // Limit the sorted data to a maximum of 12 items
  const limitedData = sortedData.slice(0, 14);
  const { firstTwo, rest } = splitArray(limitedData);

  return (
    <React.Fragment>
      <Row md={2} xs={1}>
        {firstTwo.map((ele, idx) => (
          <Col key={idx}>
            <TopMainCard data={ele} />
          </Col>
        ))}
      </Row>
      <Row className='my-3 gy-3' lg={4} md={2} xs={1}>
        {rest.map((ele, idx) => (
          <Col key={idx}>
            <TopSubCard data={ele} />
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
}

export default Top;
