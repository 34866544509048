import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { defaultTagsData } from '../../../data/data';
import './categoryGrid.css';  // CSS file for custom styles

function CategoryGrid() {
  const [newsData, setNewsData] = useState({});
  const navigate = useNavigate();
  const db = getFirestore();

  const defaultTags = defaultTagsData;

  useEffect(() => {
    const fetchNews = async () => {
      const newsByTag = {};
  
      for (const tag of defaultTags) {
        const q = query(
          collection(db, 'news'),
          where('tags', 'array-contains', tag.toLowerCase()) // Convert tag to lowercase for query
        );
        const querySnapshot = await getDocs(q);
  
        newsByTag[tag] = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            title: data.title,
            tags: data.tags.map(t => t.toLowerCase()) // Convert fetched tags to lowercase
          };
        });
  
        // Debugging line to see what articles are fetched for each tag
        console.log(`Fetched news for tag: ${tag}`, newsByTag[tag]);
      }
  
      setNewsData(newsByTag);
    };
  
    fetchNews();
  }, [db, defaultTags]);
  

  const handleShowMore = (tag) => {
    navigate(`/news/results?tag=${tag}`);
  };

  // Split tags into groups of 4 for a grid layout (3 rows, 4 columns)
  const groupedTags = [];
  for (let i = 0; i < defaultTags.length; i += 4) {
    groupedTags.push(defaultTags.slice(i, i + 4));
  }

  return (
    <Container>
      {groupedTags.map((tagGroup, rowIndex) => (
        <Row key={rowIndex} className="mb-4">
          {tagGroup.map((tag, colIndex) => (
            <Col key={colIndex} md={3}>
              <Card className="category-card">
                <Card.Body>
                  <Card.Title className="category-title text-uppercase">{tag}</Card.Title>
                  <ul className="news-list">
                    {newsData[tag]?.slice(0, 6).map((news, newsIndex) => (
                      <li
                        key={newsIndex}
                        onClick={() => { navigate("/article/" + news.id); }}
                        className="news-item"
                      >
                        {news.title}
                        <hr />
                      </li>
                    ))}
                  </ul>
                  {newsData[tag]?.length > 6 && (
                    <Button
                      variant="outline-danger"
                      className="show-more-btn"
                      onClick={() => handleShowMore(tag)}
                    >
                      Show More
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
}

export default CategoryGrid;
