import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './aboutUs.css'; // Importing CSS file
import MainNav from '../utilities/navbar/MainNav';
import SecondNav from "../utilities/navbar/SecondNav";
import Footer from '../news/footer/Footer';
const AboutUs = () => {
  return (
      <React.Fragment>
        <MainNav />
      <SecondNav></SecondNav>
    <Container fluid className="about-section">
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2 className="display-4 page-title">About CTV Telugu</h2>
          <p className="lead intro-text">
            Delivering the latest Telugu news to audiences worldwide with speed, clarity, and credibility.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={12}>
          <Card className="info-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Our Story</h4>
              <p className="section-content">
                CTV Telugu is a leading news platform dedicated to providing breaking news, entertainment, sports, and lifestyle content to Telugu-speaking audiences. Established with a vision to serve the Telugu community globally, we aim to bridge the information gap and deliver news that matters most.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={6}>
          <Card className="info-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Our Mission</h4>
              <p className="section-content">
                At CTV Telugu, we believe in delivering news with integrity and accuracy. Our mission is to keep you informed on critical events, from local news in Andhra Pradesh and Telangana to global stories. We strive to ensure the highest standards of journalism and provide a platform for meaningful discussions.
              </p>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="info-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Our Partnership</h4>
              <p className="section-content">
                CTV Telugu collaborates with industry-leading media houses and global news platforms. Our partnership with major international and national news agencies ensures comprehensive coverage and exclusive insights, reaching millions of Telugu speakers across the world.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={6}>
          <Card className="info-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Our Team</h4>
              <p className="section-content">
                Our editorial team comprises seasoned journalists, editors, and reporters committed to delivering high-quality news content. With over 1,000 news professionals across the globe, CTV Telugu operates 24/7 to ensure you stay updated with the latest stories.
              </p>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="info-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Global Reach</h4>
              <p className="section-content">
                With viewers across India and around the world, CTV Telugu connects the global Telugu-speaking community. Our content covers major national and international news, entertainment, sports, and regional stories that matter the most to our audience.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={12} className="text-center">
          <Card className="contact-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Contact Us</h4>
              <p className="section-content">
                Have any questions or feedback? Get in touch with us today.
              </p>
              <p className="contact-details">
                Email: info@ctvtelugu.com  
                <br /> Phone: +91-120-4341818, +91-22-40019000
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Footer/>
      </React.Fragment>
  );
};

export default AboutUs;
