import React from 'react'
import LatestCard from './LatestCard'
import { Col, Row } from 'react-bootstrap'
const sortByRecent = (array) => {
  return array.sort((a, b) => new Date(b.date) - new Date(a.date));  // Assuming 'date' field exists
};
function Latest({data}) {
  data=data.slice(0,18)
  data=sortByRecent(data)
  return (
    <React.Fragment>
        <Row lg={3} >
            {
              data.map((ele,idx)=><Col key={idx}><LatestCard data={ele}></LatestCard></Col>)
            }
            
        </Row>
    </React.Fragment>
  )
}

export default Latest