import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './advertiseWithUs.css'; // Importing CSS file
import MainNav from '../utilities/navbar/MainNav';
import SecondNav from '../utilities/navbar/SecondNav';
import Footer from '../news/footer/Footer';

const AdvertiseWithUs = () => {
  return (
    <React.Fragment>
      <MainNav/>
      <SecondNav></SecondNav>
    <Container fluid className="advertise-section">
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2 className="display-4 page-title">Advertise with Us</h2>
          <p className="lead intro-text">
            Partner with CTV Telugu to reach millions of Telugu-speaking audiences worldwide.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={12}>
          <Card className="info-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Why Advertise with CTV Telugu?</h4>
              <p className="section-content">
                CTV Telugu is a leading news platform catering to the diverse needs of Telugu-speaking populations globally. Advertising with us connects your brand with millions of engaged users. We provide tailored advertising solutions including display ads, video ads, sponsored content, and more, ensuring maximum reach and engagement.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={6}>
          <Card className="info-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Advertising Options</h4>
              <ul className="list-unstyled section-content">
                <li>➤ Display Ads (Banner, Sidebar)</li>
                <li>➤ Video Ads (Pre-roll, Mid-roll, Post-roll)</li>
                <li>➤ Sponsored Articles & Content</li>
                <li>➤ Native Advertising</li>
                <li>➤ Social Media Campaigns</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="info-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Audience Reach</h4>
              <p className="section-content">
                We connect you with millions of monthly viewers and strong social media engagement. Our audience includes Telugu-speaking users from Andhra Pradesh, Telangana, and global communities. Our diverse content ensures high visibility for your brand.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="text-center">
          <Card className="contact-card shadow-sm">
            <Card.Body>
              <h4 className="section-title">Contact Us</h4>
              <p className="section-content">
                Ready to start your advertising journey with us? Contact our sales team today!
              </p>
              <Button className="contact-btn" href="mailto:advertise@ctvtelugu.com">Contact Us</Button>
              <p className="mt-3 contact-details">
                Email: advertise@ctvtelugu.com  
                <br /> Phone: +91-120-4341818, +91-22-40019000
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Footer></Footer>
    </React.Fragment>
  );
};

export default AdvertiseWithUs;
