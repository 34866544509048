import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MainNav from "../../utilities/navbar/MainNav";
import SecondNav from "../../utilities/navbar/SecondNav";
import { useParams } from "react-router-dom";
import { db } from "../../../myfirestore"; // Ensure the correct path to your Firestore instance
import { doc, getDoc, collection, getDocs } from "firebase/firestore"; // Firestore imports
import { useNavigate } from "react-router-dom";
import "./article.css"; // Import the CSS file for styling

const sortByRecent = (array) => {
  return array.sort((a, b) => new Date(b.date) - new Date(a.date)); // Assuming 'date' field exists
};

function Article() {
  const { id } = useParams(); // Get the id from the URL parameters
  const [article, setArticle] = useState(null); // State to hold the main article data
  const [subArticles, setSubArticles] = useState([]); // State to hold the sub-article data
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        // Fetch the main article
        const docRef = doc(db, "news", id); // Reference to the document
        const docSnap = await getDoc(docRef); // Get the document

        if (docSnap.exists()) {
          setArticle({ id: docSnap.id, ...docSnap.data() }); // Set article data
        } else {
          console.error("No such article!"); // Handle case where article does not exist
        }

        // Fetch sub-articles of type "Featured"
        const subArticlesCollection = collection(db, "news");
        const subArticlesSnapshot = await getDocs(subArticlesCollection);
        var subArticlesData = subArticlesSnapshot.docs
          .filter((doc) => doc.data().type === "Featured") // Filter by type
          .map((doc) => ({ id: doc.id, ...doc.data() })); // Create an array of sub-articles
        subArticlesData = subArticlesData.slice(0, 5);
        subArticlesData = sortByRecent(subArticlesData);
        setSubArticles(subArticlesData); // Set sub-articles data
      } catch (error) {
        console.error("Error fetching article or sub-articles: ", error); // Handle errors
      }
    };

    fetchArticle();
  }, [id]); // Dependency array includes id

  return (
    <React.Fragment>
      <MainNav />
      <SecondNav />
      <Container fluid className="border shadow p-4 mt-4 article-container">
        <Row>
          {/* Main Article Section */}
          <Col lg={10} md={12}>
            {article ? (
              <div className="main-article">
                <h1 className="article-title">{article.title}</h1>
                <p className="text-muted article-meta">
                  {article.author} |{" "}
                  {new Date(article.date).toLocaleDateString()}
                </p>
                <div className="d-flex justify-content-center">
                  {/* Display Video if videoUrl is present, else display Image */}
                  {article.videoUrl ? (
                    <video controls width="100%" className="article-video">
                      <source src={article.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <Image
                      src={article.imageUrl}
                      alt="Main Article"
                      fluid
                      rounded
                      className="mb-4 article-image"
                    />
                  )}
                </div>
                <div className="article-containerX">
                  {article.sections.map((section, index) => (
                    <div key={index} className="article-sectionX">
                      <h3 className="article-headerX">{section.header}</h3>
                      <p className="article-descriptionX">
                        {section.description}
                      </p>
                    </div>
                  ))}
                          
                </div>
              </div>
            ) : (
              <p>Loading main article...</p> // Loading state
            )}
          </Col>

          {/* Sub Articles Section */}
          <Col lg={2} md={12}>
            {subArticles.length > 0 ? (
              subArticles.map((subArticle) => (
                <Card
                  key={subArticle.id}
                  className="border mb-3"
                  onClick={() => {
                    navigate("/article/" + subArticle.id);
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={subArticle.imageUrl}
                    className="sub-article-image"
                  />
                  <Card.Body>
                    <Card.Title className="sub-article-title">
                      {subArticle.title}
                    </Card.Title>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p>No sub-articles found.</p> // No sub-articles found
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Article;
