import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './disclaimer.css';
import MainNav from '../utilities/navbar/MainNav';
import SecondNav from '../utilities/navbar/SecondNav';
import Footer from '../news/footer/Footer';

const Disclaimer = () => {
  return (
    <React.Fragment>
      <MainNav/>
      <SecondNav></SecondNav>
    <Container className="disclaimer-container">
      <Row>
        <Col>
          <h1 className="disclaimer-title">DISCLAIMER & TERMS OF USE</h1>
          <p className="disclaimer-text">
            By accessing the CTV news website or any of its associate/group sites, you have read, understood, and agree to be legally bound by the terms of the following disclaimer and user agreement.
          </p>
          <h2 className="disclaimer-subtitle">Ownership and Content Usage</h2>
          <p className="disclaimer-text">
            This site is owned and operated by Web18 Software Services Limited ("the Company/Web18") and contains material protected by international copyright and trademark laws. Except where specifically authorized, you may not modify, copy, reproduce, republish, upload, post, transmit or distribute any material from this site, including code and software.
          </p>
          <h2 className="disclaimer-subtitle">Data Accuracy & Investment Advice</h2>
          <p className="disclaimer-text">
            Web18 has taken due care and caution in compiling the data on this website. The views and investment tips expressed by experts are their own and not those of CTV or its management. Users are advised to consult certified experts before making any investment decisions. Web18 does not guarantee the accuracy or completeness of the information provided and holds no financial liability.
          </p>
          <h2 className="disclaimer-subtitle">Changes to Terms of Use</h2>
          <p className="disclaimer-text">
            The Company reserves the right to modify the terms and conditions at any time. Any use of the site after such notice will be deemed as acceptance of the changes.
          </p>
          <h2 className="disclaimer-subtitle">Registration & User Agreement</h2>
          <p className="disclaimer-text">
            By registering, you certify that all information provided is accurate. CTV reserves the right to terminate your access if any unauthorized use is detected or if you violate the terms and conditions of this agreement.
          </p>
          <h2 className="disclaimer-subtitle">Liability Disclaimer</h2>
          <p className="disclaimer-text">
            The website may contain inaccuracies or typographical errors. CTV, its owners, and associated entities shall not be liable for any direct, indirect, or consequential damages arising from the use or inability to use this website.
          </p>
          <h2 className="disclaimer-subtitle">Jurisdiction</h2>
          <p className="disclaimer-text">
            The terms of this agreement are subject to Indian law, and any disputes arising from the use of the website will be governed by the courts of New Delhi, India.
          </p>
        </Col>
      </Row>
    </Container>
    <Footer></Footer>
    </React.Fragment>
  );
};

export default Disclaimer;
