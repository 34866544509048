import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './footer.css';  // Assuming the CSS is stored in this file
import { useNavigate } from 'react-router-dom';
import logo from "../../../../src/assets/ctv.png"

function Footer() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer-section">
      <Container className="footer-container">
        <Row className="footer-content">
          <Col md={3}><br />
            <h5 onClick={()=>navigate('news/results?tag=%23LatestNews')}>LatestNews</h5><br />
            <ul>
              <li onClick={()=>navigate('/news/results?tag=తెలంగాణ')}>తెలంగాణ</li>
              <li onClick={()=>navigate('/news/results?tag=ఆంధ్రప్రదేశ్')}>ఆంధ్రప్రదేశ్</li>
              <li onClick={()=>navigate('/news/results?tag=సినిమా')}>సినిమా</li>
              <li onClick={()=>navigate('/news/results?tag=బిజినెస్')}>బిజినెస్</li>
              <li onClick={()=>navigate('/news/results?tag=జాబ్స్ & ఎడ్యుకేషన్')}>జాబ్స్ & ఎడ్యుకేషన్</li>
              <li onClick={()=>navigate('/news/results?tag=లైఫ్ స్టైల్')}>లైఫ్ స్టైల్</li>
              <li onClick={()=>navigate('/news/results?tag=వెబ్‌స్టోరీస్')}>వెబ్‌స్టోరీస్</li>
              <li onClick={()=>navigate('/news/results?tag=వీడియో')}>వీడియో</li>
            </ul>
          </Col>
          <Col md={3}><br />
            <h5 onClick={()=>navigate('/news/results?tag=Local News')}>Local News</h5><br />
            <ul>
              <li onClick={()=>navigate('/news/results?tag=Big Boss 8')}>Big Boss 8</li>
              <li onClick={()=>navigate('/news/results?tag=షార్ట్ న్యూస్')}>షార్ట్ న్యూస్</li>
              <li onClick={()=>navigate('/news/results?tag=ఆస్ట్రాలజీ')}>ఆస్ట్రాలజీ</li>
              <li onClick={()=>navigate('/news/results?tag=క్రీడలు')}>క్రీడలు</li>
              <li onClick={()=>navigate('/news/results?tag=టెక్నాలజీ')}>టెక్నాలజీ</li>
              <li onClick={()=>navigate('/news/results?tag=జాతీయం-అంతర్జాతీయం')}>జాతీయం-అంతర్జాతీయం</li>
              <li onClick={()=>navigate('/news/results?tag=ఫోటోలు')}>ఫోటోలు</li>
              <li onClick={()=>navigate('/news/results?tag=ట్రెండిం')}>ట్రెండిం</li>
            </ul>
          </Col>
        </Row>

        {/* CTV section */}
        <Row className="footer-ctv-section py-4 mt-4">
          <Col md={3} className="d-flex align-items-center">
            <img src={logo} alt="CTV News Logo" height="80" className="d-inline-block align-top" />
          </Col>

          <Col md={3}>
            <ul>
              <li onClick={() => handleNavigation('/aboutUs')}>About Us</li>
              <li onClick={() => handleNavigation('/disclaimer')}>Disclaimer</li>
            </ul>
          </Col>

          <Col md={3}>
            <ul>
              <li onClick={() => handleNavigation('/contact')}>Contact Us</li>
              <li onClick={() => handleNavigation('/complaint-redressal')}>Complaint Redressal</li>
            </ul>
          </Col>

          <Col md={3}>
            <ul>
              <li onClick={() => handleNavigation('/advertise')}>Advertise with Us</li>
              <li onClick={() => handleNavigation('/policy')}>Privacy Policy</li>
            </ul>
          </Col>
        </Row>

        <div className="cnn-notice-container">
          <p className="cnn-notice">
            CNN name, logo and all associated elements ® and © 2024 Cable News Network LP, LLLP. A Time Warner Company. All rights reserved. CNN and the CNN logo are registered marks of Cable News Network, LP LLLP, displayed with permission. Use of the CNN name and/or logo on or as part of CTV.com does not derogate from the intellectual property rights of Cable News Network in respect of them. © Copyright CTV Media and Investments Ltd 2024. All rights reserved.
          </p>
        </div>

      </Container>
    </footer>
  );
}

export default Footer;
