import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'; // Import useSearchParams
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../myfirestore'; // Ensure the Firestore config is correct
import { Card, Container, Row, Col, Navbar } from 'react-bootstrap';
import MainNav from '../../utilities/navbar/MainNav';
import SecondNav from '../../utilities/navbar/SecondNav';

function NewsResults() {
  const [searchParams] = useSearchParams(); // Initialize useSearchParams hook
  const district = searchParams.get('district'); // Get the 'district' query param
  const tag = searchParams.get('tag'); // Get the 'tag' query param
  const [newsArticles, setNewsArticles] = useState([]);
  const [loading, setLoading] = useState(true);
 const navigate=useNavigate()

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsQuery = collection(db, 'news'); // Fetch all news articles
        const querySnapshot = await getDocs(newsQuery);
        const fetchedArticles = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            // Split tags into an array and normalize to lowercase
            tags: data.tags.map(tag => tag.trim().toLowerCase()),
            districtName: data.districtName?.toLowerCase().trim(), // Normalize district name to lowercase and trim
          };
        });

        // Normalize the case for the district and tag
        const normalizedDistrict = district ? district.toLowerCase().trim() : null;
        const normalizedTag = tag ? tag.toLowerCase().trim() : null;

        // Filter fetched articles to match district and tag
        const filteredArticles = fetchedArticles.filter(article => {
          // Prioritize filtering by district if both fields are present
          if (normalizedDistrict) {
            console.log(article.districtName === normalizedDistrict)

            return article.districtName === normalizedDistrict && article.approved; // Match district
          } 
          // If only the tag is provided, check for tag matches
          if (normalizedTag) {
            console.log(article.tags,normalizedTag)
            return article.tags.includes(normalizedTag); // Match tag
          }
          // If neither is provided, return all articles
          return true;
        });

        setNewsArticles(filteredArticles);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching news:', error);
        setLoading(false);
      }
    };

    fetchNews();
  }, [district, tag]); // Re-run the query whenever district or tag changes

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
   <React.Fragment><MainNav></MainNav>  <SecondNav></SecondNav> <Container>
   <Row>
     {newsArticles.length > 0 ? (
       newsArticles.map((article) => (
         <Col key={article.id} md={4} className='gy-3'>
           <Card className="mb-4 h-100" onClick={() => { navigate("/article/" + article.id); }}>
             <Card.Img  style={{height:'60%'}} variant="top" src={article.imageUrl} alt={article.title} />
             <Card.Body  style={{height:'40%'}}>
               <Card.Title>{article.title}</Card.Title>
               <Card.Text>{article.synopsis}</Card.Text>
             </Card.Body>
           </Card>
         </Col>
       ))
     ) : (
       <div>No articles found for the given criteria.</div>
     )}
   </Row>
 </Container></React.Fragment>
  );
}

export default NewsResults;
