import React from 'react';
import { Container } from 'react-bootstrap';
import './policy.css';
import MainNav from '../utilities/navbar/MainNav';
import SecondNav from '../utilities/navbar/SecondNav';
import Footer from '../news/footer/Footer';

const Policy = () => {
    return (
        <React.Fragment>
           <MainNav/> 
           <SecondNav></SecondNav>
        <Container className="my-4">
            <h1 className="text-center">Privacy Policy for CTV</h1>
            <h3>For Other Residents</h3>
            <h3>For EU, UK, and California Residents</h3>

            <h2>Our Commitment</h2>
            <p>
                CTV ("we", "us", "our") is committed to protecting the privacy of the users ("you" / "your" / "yourself") of its website and software applications (collectively, "Applications"). This privacy policy ("Policy") is provided to familiarize you with how we use and disclose your information collected through the Applications.
            </p>
            <p>
                The terms of this Policy govern your use of any and all of the Applications and the information accessible on or from these Applications. The Policy also lays down how we may collect, use, and share any information you provide. We reserve the right to change, modify, add, or remove portions of this Policy at any time. We recommend reviewing this Policy periodically to ensure you are aware of the current privacy practices.
            </p>
            <p>
                This Policy shall be construed in compliance with the Information Technology Act, 2000, as amended, and read with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.
            </p>

            <h2>1. Collection of Information</h2>
            <ol>
                <li>
                    We may collect the following two types of information:
                    <ul>
                        <li>
                            <strong>Personal Information:</strong> Information that could reasonably be used to identify you personally, such as your name, email address, physical address, or other relevant personal details. Sensitive personal data or information, which may include passwords and financial information, is also considered part of Personal Information.
                        </li>
                        <li>
                            <strong>Non-Personal Information:</strong> Information that does not identify you or any other individual, including session data, web beacons, usage data, and aggregate information. This information is used to improve the Applications and enhance user experience.
                        </li>
                    </ul>
                </li>
                <li>
                    You consent to the installation of cookies or other similar data collection software on your device.
                </li>
            </ol>

            <h2>2. Use of Information</h2>
            <ol>
                <li>
                    Your Personal Information may be used for various purposes, including:
                    <ul>
                        <li>Processing orders or applications.</li>
                        <li>Providing services and improving our offerings.</li>
                        <li>Marketing and promotional communications.</li>
                        <li>Responding to your inquiries and fulfilling requests.</li>
                    </ul>
                </li>
                <li>Your Personal Information will be kept confidential to the maximum extent possible.</li>
                <li>We may use Non-Personal Information to analyze trends and user behavior on the Applications.</li>
            </ol>

            <h2>3. Disclosure</h2>
            <ol>
                <li>
                    We do not sell or rent Personal Information. Disclosure may occur under the following circumstances:
                    <ul>
                        <li>To affiliates, consultants, and vendors for service provision.</li>
                        <li>To partners and advertisers for marketing and advertising purposes.</li>
                        <li>As required by law or to protect our rights and those of others.</li>
                    </ul>
                </li>
            </ol>

            <h2>4. Information Security and Storage</h2>
            <ol>
                <li>
                    We use reasonable security measures to safeguard your data. Your information may be stored in physical and electronic form, possibly in countries outside India.
                </li>
                <li>We are not responsible for breaches caused by Force Majeure events.</li>
            </ol>

            <h2>5. Third-Party Websites, Applications, and Services</h2>
            <p>
                The Applications may include links to other websites, which are governed by their respective privacy policies. We use YouTube API Services to showcase video content, and by using our Applications, you accept the YouTube Terms of Service and Google privacy policy.
            </p>

            <h2>6. Access, Correction, and Deletion</h2>
            <p>
                You can request access to, correction of, or deletion of your information by contacting us at <a href="mailto:dpo@yourdomain.com">dpo@yourdomain.com</a>. Please note that certain data may not be correctable or deletable under applicable law.
            </p>

            <p>If you have questions or concerns about this Policy, please contact us at <a href="mailto:dpo@yourdomain.com">dpo@yourdomain.com</a>.</p>
        </Container>
        <Footer></Footer>
        </React.Fragment>
    );
};

export default Policy;
