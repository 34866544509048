import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./home.css";
import News from "../news/News";
import MainNav from "../utilities/navbar/MainNav";
import SecondNav from "../utilities/navbar/SecondNav";
import AdminNav from "../utilities/navbar/AdminNav";

function Home() {
 

  return (
    <React.Fragment>
      <MainNav />
      <SecondNav></SecondNav>
      <AdminNav></AdminNav>
      <News />
    </React.Fragment>
  );
}

export default Home;
