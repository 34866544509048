import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { collection, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../myfirestore'; // Ensure this path is correct

function Admincp() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true); // Set loading true before fetching
      try {
        const querySnapshot = await getDocs(collection(db, 'news'));
        const fetchedArticles = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          isEditing: false, // Initialize isEditing state for each article
        }));

        // Sort articles by the date added, assuming you have a 'date' field
        const sortedArticles = fetchedArticles.sort((a, b) => new Date(b.date) - new Date(a.date));
        setArticles(sortedArticles);
      } catch (error) {
        setError('Error fetching articles: ' + error.message);
      } finally {
        setLoading(false); // Set loading false after fetching
      }
    };

    fetchArticles();
  }, []);

  // Function to handle approval of an article
  const handleApprove = async (id) => {
    try {
      const articleRef = doc(db, 'news', id);
      await updateDoc(articleRef, { approved: true }); // Set approved field to true
      setArticles(prevArticles => 
        prevArticles.map(article => 
          article.id === id ? { ...article, approved: true } : article
        )
      );
    } catch (error) {
      setError('Error approving article: ' + error.message);
    }
  };

  // Function to handle rejection of an article
  const handleReject = async (id) => {
    try {
      const articleRef = doc(db, 'news', id);
      await updateDoc(articleRef, { approved: false }); // Set approved field to false
      setArticles(prevArticles => 
        prevArticles.map(article => 
          article.id === id ? { ...article, approved: false } : article
        )
      );
    } catch (error) {
      setError('Error rejecting article: ' + error.message);
    }
  };

  const handleDelete = async (articleId) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        const articleRef = doc(db, "news", articleId); // Adjust 'news' if your collection name differs
        await deleteDoc(articleRef);
        console.log(`Successfully deleted article with ID: ${articleId}`);
        // Update state to remove deleted article from the UI
        setArticles(prevArticles => prevArticles.filter(article => article.id !== articleId));
      } catch (error) {
        console.error("Error deleting article: ", error);
        alert("Failed to delete the article. Please try again.");
      }
    }
  };
  
 // Function to handle edit toggle
  const handleEditToggle = (id) => {
    setArticles(articles.map(article => 
      article.id === id ? { ...article, isEditing: !article.isEditing } : article
    ));
  };

  // Function to handle saving the edited article
  const handleSave = async (id) => {
    try {
      const updatedArticle = articles.find(article => article.id === id);
      const articleRef = doc(db, 'news', id);
      await updateDoc(articleRef, updatedArticle); // Update the article in Firestore
      setArticles(articles.map(article => 
        article.id === id ? { ...updatedArticle, isEditing: false } : article
      ));
    } catch (error) {
      setError('Error updating article: ' + error.message);
    }
  };

  // Function to handle input change for editing
  const handleInputChange = (id, field, value) => {
    setArticles(articles.map(article =>
      article.id === id ? { ...article, [field]: value } : article
    ));
  };

  if (loading) return <p>Loading articles...</p>; // Loading feedback
  if (error) return <p className="text-danger">{error}</p>; // Error feedback

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Admin Control Panel</h2>
      <div className="row">
        {articles.map((article) => (
          <div key={article.id} className="col-md-6 mb-4">
            <div className="card">
              <img src={article.imageUrl} className="card-img-top" alt={article.title} />
              <div className="card-body">
                {article.isEditing ? (
                  <>
                    {/* Editable Fields */}
                    <input
                      type="text"
                      value={article.title}
                      onChange={(e) => handleInputChange(article.id, 'title', e.target.value)}
                      className="form-control mb-2"
                      placeholder="Title"
                    />
                    <textarea
                      value={article.description}
                      onChange={(e) => handleInputChange(article.id, 'description', e.target.value)}
                      className="form-control mb-2"
                      placeholder="Description"
                    />
                    <textarea
                      value={article.synopsis}
                      onChange={(e) => handleInputChange(article.id, 'synopsis', e.target.value)}
                      className="form-control mb-2"
                      placeholder="Synopsis"
                    />
                    <input
                      type="text"
                      value={article.tags?.join(', ')}
                      onChange={(e) => handleInputChange(article.id, 'tags', e.target.value.split(',').map(tag => tag.trim()))}
                      className="form-control mb-2"
                      placeholder="Tags (comma-separated)"
                    />
                    <input
                      type="text"
                      value={article.type}
                      onChange={(e) => handleInputChange(article.id, 'type', e.target.value)}
                      className="form-control mb-2"
                      placeholder="Type"
                    />
                    <input
                      type="date"
                      value={article.date.split('T')[0]} // Ensure correct date format
                      onChange={(e) => handleInputChange(article.id, 'date', e.target.value)}
                      className="form-control mb-2"
                      placeholder="Date"
                    />
                    <button
                      className="btn btn-info"
                      onClick={() => handleSave(article.id)}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    {/* Read-only Fields */}
                    <h5 className="card-title">{article.title}</h5>
                    <p className="card-text">{article.description}</p>
                    <p className="card-text"><strong>Synopsis:</strong> {article.synopsis}</p>
                    <p className="card-text"><strong>Tags:</strong> {article.tags?.join(', ')}</p>
                    <p className="card-text"><strong>Type:</strong> {article.type}</p>
                    <p className="card-text"><strong>Date:</strong> {article.date}</p>
                  </>
                )}

                <p className="card-text"><strong>Author:</strong> {article.author}</p>

                <div className="d-flex justify-content-between">
                  {!article.approved && (
                    <button
                      className="btn btn-success"
                      onClick={() => handleApprove(article.id)}
                    >
                      Approve
                    </button>
                  )}
                  {article.approved && (
                    <button
                      className="btn btn-danger"
                      onClick={() => handleReject(article.id)}
                    >
                      Reject
                    </button>
                  )}
                  <button
                    className="btn btn-primary"
                    onClick={() => handleEditToggle(article.id)}
                  >
                    {article.isEditing ? "Cancel" : "Edit"}
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(article.id)}
                  >
                    Delete
                  </button>
                </div>

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Admincp;
