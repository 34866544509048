import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import './featured.css';

function FeaturedCard({ data }) {
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle card click to navigate to the article page
  const handleCardClick = () => {
    navigate(`/article/${data.id}`); // Redirect to article/:id page
  };

  return (
    <Card className='my-3 shadow-sm ' onClick={handleCardClick} style={{ cursor: 'pointer',height:'18rem' }}>
      <Card.Img variant="top" src={data.imageUrl} alt={data.title} className="p-2 card-image" style={{height:'60%'}}/>
      <Card.Body className='p-2' style={{height:'40%'}}>
        <Card.Text className='fs-8 hover-red'>{data.title}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default FeaturedCard;
