import React, { useRef, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import './shorts.css';
import { useNavigate } from 'react-router-dom';

function Shorts({ data }) {
  const scrollRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const cardRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, [data]);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -cardWidth, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' });
  };

  return (
    <div className="shorts-container position-relative" style={{ cursor: 'pointer' }}>
      <Button variant="outline-light" className="scroll-btn left-btn" onClick={scrollLeft}>
        &#10094;
      </Button>
      <div className="shorts-wrapper d-flex overflow-hidden" ref={scrollRef}>
        {data.map((item, index) => (
          <Card onClick={() => navigate('/article/' + item.id)} key={index} className="shorts-card mx-2" ref={index === 0 ? cardRef : null}>
            {item.type === 'video' ? (
              <video width="100%" height="250" controls className="shorts-video">
                <source src={item.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <Card.Img variant="top" src={item.imageUrl} className="shorts-image" />
            )}
            <Card.Body>
              <Card.Text className="fs-8 text-center hover-red">{item.title}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Button variant="outline-light" className="scroll-btn right-btn" onClick={scrollRight}>
        &#10095;
      </Button>
    </div>
  );
}

export default Shorts;
